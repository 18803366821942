import logo from './logo.svg';
import './App.css';

function App() {
  return (
  <div>
    <meta http-equiv="Refresh" content="0; url=//www.discord.gg/C5T6zvr56n" />
    <p>si a redirecao falhar <a href="//www.discord.gg/C5T6zvr56n/">clique aqui</a>.</p>
  </div>
  );
}

export default App;

//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
